import { Grid, Typography } from '@mui/material';
import NextImage from 'next/image';
import NextLink from 'next/link';
import PropTypes from 'prop-types';

import RichText from '@/components/RichText/RichText';
import StrapiImage from '@/components/StrapiImage/StrapiImage';
import Chip from '@/components/ui/Chip/Chip';

import {
  Card as CardBase,
  CardActionArea,
  CardContent,
  CardMedia,
  DescriptionText,
} from './Card.style';
import Link from './Link/Link';

const Card = ({
  card,
  twoColumLayout,
  centerImage,
  showLinkText,
  linkText,
  headingVariant,
}) => {
  const {
    headline,
    subtitle,
    description,
    tag,
    imageTag,
    image,
    link,
    cardAreaLink,
    onClick,
    onCardAreaClick,
    'data-cy': dataCy,
  } = card;

  const hasTwoActions = (onClick || link) && (onCardAreaClick || cardAreaLink);

  const getColumnConfig = () => {
    return {
      lg: twoColumLayout ? 6 : 4,
      sm: 6,
      xs: 12,
    };
  };

  return (
    <Grid
      item
      {...getColumnConfig()}
      display="flex"
      data-cy={dataCy || 'card-grid'}>
      <CardBase variant="outlined">
        <CardActionArea
          onClick={cardAreaLink ? onCardAreaClick : onClick}
          {...(cardAreaLink || (link && !onCardAreaClick)
            ? {
                component: NextLink,
                href: cardAreaLink || link,
              }
            : {})}>
          <CardMedia centerImage={centerImage}>
            {typeof image === 'object' ? (
              <StrapiImage
                data={image?.data}
                sizes="(max-width: 548px) 100vw, 360px"
                fill
                style={{ objectFit: 'cover' }}
              />
            ) : (
              <NextImage
                src={image}
                alt=""
                fill
                sizes="(max-width: 548px) 100vw, 360px"
                style={{ objectFit: 'cover' }}
              />
            )}
            {imageTag && (
              <Chip
                color="success"
                uppercase
                sx={{ position: 'absolute', left: 16, top: 16 }}>
                {imageTag}
              </Chip>
            )}
          </CardMedia>
          <CardContent>
            {tag && (
              <Chip
                size="small"
                uppercase
                sx={{
                  alignSelf: 'flex-start',
                  mb: 1,
                }}
                data-cy="chip">
                {tag}
              </Chip>
            )}
            <Typography
              variant={`${headingVariant}Redesign`}
              component="div"
              color="primary.dark"
              dangerouslySetInnerHTML={{ __html: headline }}
              data-cy="headline"
            />
            {subtitle &&
              (typeof subtitle === 'string' ? (
                <Typography
                  variant="h6Redesign"
                  component="div"
                  color="primary.main"
                  dangerouslySetInnerHTML={{ __html: subtitle }}
                  data-cy="subtitle"
                />
              ) : (
                subtitle
              ))}
            {description && (
              <DescriptionText data-cy="description">
                <RichText data={description} />
              </DescriptionText>
            )}
            {!hasTwoActions && (showLinkText || description) && (
              <Link linkText={linkText} sx={{ pt: 1 }} />
            )}
          </CardContent>
        </CardActionArea>
        {hasTwoActions && (
          <Link
            linkText={linkText}
            link={link}
            onClick={onClick}
            component="button"
            sx={{ p: 2 }}
          />
        )}
      </CardBase>
    </Grid>
  );
};

Card.propTypes = {
  card: PropTypes.shape({
    headline: PropTypes.string.isRequired,
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    description: PropTypes.string,
    tag: PropTypes.string,
    imageTag: PropTypes.string,
    image: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        data: PropTypes.shape({
          attributes: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
      }),
    ]),
    link: PropTypes.string,
    onClick: PropTypes.func,
    // if cardAreaLink or onCardAreaClick are passed,
    // then link and onClick props are used as secondary card action
    cardAreaLink: PropTypes.string,
    onCardAreaClick: PropTypes.func,
    'data-cy': PropTypes.string,
  }),
  twoColumLayout: PropTypes.bool,
  centerImage: PropTypes.bool,
  headingVariant: PropTypes.oneOf(['h6', 'h5', 'h4']),
  showLinkText: PropTypes.bool,
  linkText: PropTypes.string,
};

export default Card;
