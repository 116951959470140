import {
  Card as MuiCard,
  CardActionArea as MuiCardActionArea,
  cardClasses,
  CardContent as MuiCardContent,
  CardMedia as MuiCardMedia,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const Card = styled(MuiCard)(() => ({
  [`&.${cardClasses.root}`]: {
    borderRadius: 15,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const CardActionArea = styled(MuiCardActionArea)(() => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
}));

export const CardMedia = styled(MuiCardMedia, {
  shouldForwardProp: prop => prop !== 'centerImage',
})(({ centerImage }) => ({
  position: 'relative',
  width: '100%',
  height: 200,
  ...(centerImage && {
    img: {
      objectFit: 'contain !important',
      maxWidth: '90% !important',
      maxHeight: '90% !important',
      top: '50% !important',
      left: '50% !important',
      transform: 'translate(-50%, -50%) !important',
    },
  }),
}));

export const CardContent = styled(MuiCardContent)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyItems: 'flex-start',
  flex: 1,
}));

export const DescriptionText = styled('div')(({ theme }) => ({
  flex: 1,
  p: {
    ...theme.typography.body1Redesign,
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: '3',
    WebkitBoxOrient: 'vertical',
    lineClamp: 3,
  },
}));
