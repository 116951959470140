import { ArrowForward } from '@mui/icons-material';
import { Link as MuiLink, Stack } from '@mui/material';
import NextLink from 'next/link';
import PropTypes from 'prop-types';

import useFormatMessage from '@/hooks/useFormatMessage';

const Link = ({ onClick, link, linkText, component = 'div', sx }) => {
  const formatMessage = useFormatMessage();

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={0.5}
      mt="auto"
      data-cy="link"
      sx={sx}>
      <MuiLink
        underline="hover"
        component={component}
        onClick={onClick}
        variant="body2Redesign"
        {...(link
          ? {
              component: NextLink,
              href: link,
            }
          : {})}>
        <span>{linkText || formatMessage('card_grid_link_show_more')}</span>
      </MuiLink>
      <ArrowForward color="primary" fontSize="small" />
    </Stack>
  );
};

Link.propTypes = {
  onClick: PropTypes.func,
  link: PropTypes.string,
  linkText: PropTypes.string,
  component: PropTypes.string,
  sx: PropTypes.object,
};

export default Link;
