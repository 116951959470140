import { Grid, Skeleton, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import Card from './Card/Card';
import {
  Card as CardBase,
  CardActionArea,
  CardContent,
  CardMedia,
} from './Card/Card.style';
import { CardsWrapper } from './CardGrid.style';

const CardGrid = ({
  cards,
  twoColumLayout = false,
  centerImage = false,
  headingVariant = 'h6',
  showLinkText,
  linkText,
  'data-cy': dataCy,
  loading = false,
  skeletonCardsNumber = 6,
}) => {
  const getColumnConfig = () => {
    return {
      lg: twoColumLayout ? 6 : 4,
      sm: 6,
      xs: 12,
    };
  };

  if (loading) {
    return (
      <CardsWrapper>
        <Grid
          container
          columnSpacing={{ md: 3.5, xs: 2 }}
          rowSpacing={{ xs: 5, md: 12.5 }}
          mb={7.5}>
          {Array.from(new Array(skeletonCardsNumber)).map((_, idx) => (
            <Grid item key={idx} {...getColumnConfig()} display="flex">
              <CardBase variant="outlined">
                <CardActionArea>
                  <CardMedia>
                    <Skeleton variant="rectangular" width="100%" height={200} />
                  </CardMedia>
                  <CardContent>
                    <Typography component="div" variant="captionRedesign">
                      <Skeleton variant="text" width="20%" />
                    </Typography>
                    <Typography
                      component="div"
                      variant={`${headingVariant}Redesign`}>
                      <Skeleton variant="text" width="100%" />
                    </Typography>
                    <Typography component="div" variant="h6Redesign">
                      <Skeleton variant="text" width="60%" />
                    </Typography>
                    <Typography component="div" variant="body1Redesign">
                      <Skeleton variant="text" width="100%" />
                      <Skeleton variant="text" width="100%" />
                      <Skeleton variant="text" width="100%" />
                      <Skeleton variant="text" width="30%" />
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </CardBase>
            </Grid>
          ))}
        </Grid>
      </CardsWrapper>
    );
  }

  return (
    <CardsWrapper data-cy={dataCy}>
      <Grid
        container
        columnSpacing={{ md: 3.5, xs: 2 }}
        rowSpacing={{ xs: 5, md: 12.5 }}
        mb={7.5}>
        {cards.map((card, idx) => (
          <Card
            card={card}
            key={idx}
            twoColumLayout={twoColumLayout}
            centerImage={centerImage}
            showLinkText={showLinkText}
            linkText={linkText}
            headingVariant={headingVariant}
          />
        ))}
      </Grid>
    </CardsWrapper>
  );
};

CardGrid.propTypes = {
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      headline: PropTypes.string.isRequired,
      subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      image: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          data: PropTypes.shape({
            attributes: PropTypes.shape({
              url: PropTypes.string,
            }),
          }),
        }),
      ]),
      link: PropTypes.string,
      cardAreaLink: PropTypes.string,
      description: PropTypes.string,
      onClick: PropTypes.func,
      onCardAreaClick: PropTypes.func,
      'data-cy': PropTypes.string,
    })
  ),
  twoColumLayout: PropTypes.bool,
  centerImage: PropTypes.bool,
  headingVariant: PropTypes.oneOf(['h6', 'h5', 'h4']),
  showLinkText: PropTypes.bool,
  linkText: PropTypes.string,
  'data-cy': PropTypes.string,
  loading: PropTypes.bool,
  skeletonCardsNumber: PropTypes.number,
};

export default CardGrid;
